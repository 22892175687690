<template>
    <TrustpilotTestimonialsGridBlock v-editable="block" :block="blockData" />
</template>

<script setup lang="ts">
import useStoryblokBlock from '~/composables/useStoryblokBlock';
import { TrustpilotTestimonialsGridBlock as BlockData } from '~/types/blocks/TrustpilotTestimonialsGridBlock';
import TrustpilotTestimonialsGridBlock from '~/components/page-building/TrustpilotTestimonialsGridBlock.vue';

const props = defineProps<{
    block: any;
    nextBlock?: any;
}>();

const { settings } = useStoryblokBlock(
    computed(() => props.block),
    computed(() => props.nextBlock),
);

const blockData = computed<BlockData>(() => settings.value);
</script>
